import React from 'react'

import Layout from '../../components/layout'
import { Services } from '../../components/services'
import Seo from '../../components/seo'



const BydgoszczServices = () => {

    return (
        <Layout bydgoszcz>
            <Seo
              title="Modelowanie sylwetki Bydgoszcz"
              description="Klinika kosmetologii estetycznej, modelowania sylwetki i laseroterapii w Bydgoszczy."
              url="https://bodymedgroup.pl/bydgoszcz-zabiegi"
            />
            <Services bydgoszcz/>
        </Layout>
    )
}

export default BydgoszczServices